import { isIOS, isMobile } from 'react-device-detect';
import { FTUE_STEPS_KEYS } from 'shared/constants';
import styled from 'styled-components';
import { isNotStandaloneMobilePWA } from '../../utils/mobileSupported';

const viewportWidth = window.innerWidth;
const vwInPixels = viewportWidth / 100;
const GAME_TILE_WIDTH = 16 * vwInPixels;

const getScrollBarConfig = (ftueStep, gameIndex, hasPromotions, totalGamesCount, showBanner, lobbyPromotionsLength, areBannersEnabled) => {
  const maxVisibleGames = isMobile ? 0 : 8;
  const hasMoreGamesThanVisible = totalGamesCount > maxVisibleGames;
  const bannersAffectScroll = areBannersEnabled && totalGamesCount > 2;
  return {
    isDraggableX: true,
    showVertical: false,
    showHorizontal: hasMoreGamesThanVisible || bannersAffectScroll,
    showHorizontalArrows: false,
    showByDefault: true,
    scrollToPosition: ftueStep === FTUE_STEPS_KEYS.HIGHLIGHT_A_GAME || showBanner ? 0 : gameIndex * GAME_TILE_WIDTH,
    customStyle: {
      display: 'flex',
      height: '100%',
      paddingTop: '1vh',
      paddingLeft: hasPromotions
        ? lobbyPromotionsLength > 1
          ? '30vw'
          : isNotStandaloneMobilePWA
            ? '27vw'
            : '31.5vw'
        : '8vw',
    },
    customWidth: isNotStandaloneMobilePWA ? '100%' : '97vw',
    ...(isNotStandaloneMobilePWA && {
      containerStyle: {
        overflowX: 'scroll',
        width: '100vw',
        minWidth: '100vw',
      },
    }),
    customClassNames: isIOS
      ? 'custom-scrollbar'
      : `${hasPromotions ? 'custom-scrollbar-gamepage' : 'custom-scrollbar-gamepage custom-scrollbar-gamepage-no-promo-banner'}`,
  };
};

const MainContainer = styled.div`
  display: flex;
  padding-bottom: 2rem;

  @media only screen and (max-height: 780px) {
    padding-bottom: 3.5rem;
  }
`;

export { getScrollBarConfig, MainContainer };

