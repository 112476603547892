import React, { Component } from 'react';
import styled from 'styled-components';
import { isTablet } from 'react-device-detect';
import Animated from 'animated/lib/targets/react-dom';
import ImageBW from '../../components/ImageBoundsWrapper';
import SBonusLinkMessage from 'shared/screens/BonusLinkMessage';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import ThemeContext from 'shared/context/ThemeContext';
import Button from '../../components/Button/Button';
import { headerContainerStyle, wrapperStyle } from './components/styledComponents';
import ModalHeader from './components/ModalHeader';
import generateRender from 'shared/utils/generateRender';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import { pwaStyleAdapter } from '../../utils/mobileSupported';

const COINS = 'coins';

const buttonImageSource = generateRender({
  ti: assetSource(ASSET_KEY.ROUNDED_RECT_SECONDARY),
  default: assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY),
});

export default class BonusLinkMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const themeContext = this.context;

    return (
      <SBonusLinkMessage
        {...this.props}
        render={
          /**
           *  @param {Object} args
           *  @param {String[]} args.apiRootList
           */
          args => {
            const { redeem, format } = args;
            if (!redeem) {
              return (
                <>
                  <div className="ModalBackground hideBefore">
                    <ImageBW
                      className="ModalBackgroundAsset"
                      as={Animated.div}
                      source={assetSource(ASSET_KEY.MODAL_BACKING_9_SLICE_WEB)}
                      ibw={require(asset`modal-backing-9-slice.ibw`)}
                      widthScale={0.5}
                    />
                  </div>
                  <div className="ModalCloseButton" onClick={this.props.dismissModal}>
                    <ImageBW
                      source={assetSource(ASSET_KEY.CLOSE_BUTTON)}
                      ibw={require(asset`close-button.ibw`)}
                      manualAssetScale={3}
                      style={Styles.ModalCloseButton}
                      innerStyle={Styles.ModalCloseButtonInner}
                      alt="Close"
                    />
                  </div>
                  <ModalContainer>
                    <ModalHeader
                      wrapperStyle={wrapperStyle}
                      containerStyle={headerContainerStyle}
                      title={getText(TEXT_KEY.BONUS_LINK_MESSAGE_INVALID_TITLE)}
                    />
                    <ErrorMessageWrapper>
                      <WarningSymbol src={assetSource(ASSET_KEY.WARNING_SYMBOL)} alt="Warning Symbol" />
                      <ErrorTextWrapper>{getText(TEXT_KEY.BONUS_LINK_MESSAGE_INVALID_DESCRIPTION)}</ErrorTextWrapper>
                      <Button
                        label={getText(TEXT_KEY.OK)}
                        onClick={this.props.dismissModal}
                        imageStyle={Styles.ButtonImageStyle}
                        imageSource={assetSource(ASSET_KEY.ROUNDED_RECT_PRIMARY)}
                        containerStyle={Styles.ButtonContainer}
                        textStyle={Styles.ButtonText}
                      />
                    </ErrorMessageWrapper>
                  </ModalContainer>
                </>
              );
            }

            return (
              <div style={themeContext.BonusLinkMessage.BoxContainer}>
                <ImageBW
                  source={
                    redeem.currency === COINS
                      ? assetSource(ASSET_KEY.PROMO_IMAGE_COINS)
                      : assetSource(ASSET_KEY.PROMO_IMAGE_LOYALTY)
                  }
                  ibw={
                    redeem.currency === COINS
                      ? require(asset`promo-image-coins.ibw`)
                      : require(asset`promo-image-loyalty.ibw`)
                  }
                  style={Styles.BunosImageStyle}
                  resizeMode="contain"
                />
                <div
                  style={
                    redeem.currency === COINS
                      ? themeContext.BonusLinkMessage.CoinsBonusContainer
                      : themeContext.BonusLinkMessage.LPBonusContainer
                  }
                >
                  <p style={themeContext.BonusLinkMessage.BonusText}>
                    {getText(TEXT_KEY.BONUS_LINK_MESSAGE_DESCRIPTION)}
                  </p>
                  <div style={themeContext.BonusLinkMessage.BonusInfoContainer}>
                    <ImageBW
                      source={
                        redeem.currency === COINS
                          ? assetSource(ASSET_KEY.COIN_ICON)
                          : assetSource(ASSET_KEY.LOYALTY_ICON)
                      }
                      ibw={redeem.currency === COINS ? require(asset`coin-icon.ibw`) : require(asset`loyalty-icon.ibw`)}
                      style={themeContext.BonusLinkMessage.CoinImage}
                      alt="Close"
                    />
                    <h1>{format(redeem.amount)}</h1>
                  </div>
                  <Button
                    containerStyle={themeContext.BonusLinkMessage.BonusButtonContainer}
                    imageSource={buttonImageSource}
                    label={getText(TEXT_KEY.BONUS_LINK_MESSAGE_BUTTON)}
                    textStyle={themeContext.BonusLinkMessage.BonusLinkButton}
                    onClick={this.props.dismissModal}
                  />
                </div>
              </div>
            );
          }
        }
      />
    );
  }
}

BonusLinkMessage.hideDefaultBackground = true;
BonusLinkMessage.hideCloseButton = true;
BonusLinkMessage.contextType = ThemeContext;

const Styles = {
  ModalCloseButton: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
  ModalCloseButtonInner: {
    cursor: 'pointer',
  },
  ButtonImageStyle: {
    width: '25%',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  ButtonContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    margin: 20,
  },
  ButtonText: {
    position: 'relative',
    top: '0.4rem',
  },
  BunosImageStyle: {
    width: '100%',
    height: 'fit-content',
  },
};

const ModalContainer = styled.div`
  position: relative;
  flex: 1;
`;

const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const WarningSymbol = styled.img`
  width: 9%;
  z-index: 1;
  margin: 1rem 0;
`;

const ErrorTextWrapper = styled.span`
  font-weight: 500;
  font-size: ${pwaStyleAdapter(1, 'rem', isTablet ? 2 : 1.3)};
  text-align: center;
`;

